.verticalListPanel {
	composes: g-grid-container from global;
	composes: g-grid from global;
	row-gap: var(--hdsplus-spacing-08);
	
	@media (--medium-up) {
		row-gap: var(--hdsplus-spacing-09);
	}

	@media (--large) {
		row-gap: var(--hdsplus-spacing-12);
	}
}

.headingContainer {
	grid-column: 1 / -1;
	display: flex;
	flex-direction: column;
	gap: var(--hdsplus-spacing-04);

	@media (--medium-up) {
		grid-column: 1 / span 9;
	}

	@media (--large) {
		grid-column: 1 / span 7;
	}	
}

.mobileDropdown {
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: 1 / span 5;
	}

	@media (--large) {
		display: none;
	}
}

.tabsRoot {
	grid-column: 1 / -1;
	display: flex;

	@media (--large) {
		gap: var(--hdsplus-spacing-13);
	}
}

.tabsList {
	display: none;
	padding: 0;
	margin: 0;
	flex-direction: column;
	gap: var(--hdsplus-spacing-03);
	flex-shrink: 0;
	min-width: 25%;
	width: 25%;

	@media (--large) {
		display: flex;
	}
}

.tabListTrigger {
	width: 100%;
	padding: var(--hdsplus-spacing-03) var(--hdsplus-spacing-05);
  display: flex;
  align-items: center;
  color: inherit;
  border: none;
  border-radius: 5px;
  cursor: pointer;
	background: transparent;
	
	&:hover {
		background: var(--token-color-surface-interactive-hover);
	}

	&[data-state='active'] {
		background: var(--token-color-surface-interactive-active);
	}
}

.tabListTriggerText {
	text-align: left;
}

.panels {
	display: grid;
	border-radius: var(--hdsplus-spacing-03);
	border: 1px solid var(--token-color-border-faint);
	background: var(--token-color-surface-strong);
	overflow: hidden;
}

.panelWrapper {
	grid-area: 1 / -1;
}

.panel {
	grid-area: 1 / -1;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;

	@media (--medium-up) {
		flex-direction: row;
	}
}

.panelImageContainer {
	width: 100%;
	display: flex;

	@media (--medium-up) {
		height: 100%;
		width: 43%;
		min-width: 43%;
	}

	@media (--large) {
		width: 39%;
		min-width: 39%;
	}
}

.panelImage {
	width: 100%;
	height: auto;
	object-fit: cover;
}

.panelContent {
	padding: var(--hdsplus-spacing-08);
	display: flex;
	flex-direction: column;
	gap: var(--hdsplus-spacing-03);
	justify-content: center;
	flex-grow: 1;

	@media (--medium-up) {
		padding: 0 var(--hdsplus-spacing-09);
	}

	@media (--large) {
		padding: 0 var(--hdsplus-spacing-13);
	}
}

.cta {
	margin-top: var(--hdsplus-spacing-03);
}
